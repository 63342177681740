// libraries
import { atom, GetRecoilValue, selector, selectorFamily } from 'recoil'
import _ from 'lodash'
import { feature as turfFeature } from '@turf/helpers'
import { useApolloClient } from '@apollo/client'

// utils
import { validateGeojson } from 'helpers/geojson'
import { getRelayEdgesData, SuGraphqlError } from 'helpers/graphql'
import { sessionUserGroupState } from 'recoilStore/userStore'
import { getAssetsByType } from '../services/api/assetType'

import { DEFAULT_ASSET_EXTERNAL_ID } from '../constants/common'
import { GET_AVAILABLE_ASSET_TYPES } from './query'
import { AssetType } from '../types/graphql'
import {
  ASSET_TYPES,
  ASSET_TYPE_ID_ORDER,
  AssetTypeId,
} from '../constants/asset'

type AssetQueryParams = Partial<{
  properties: string[]
  assetsOmitFields: string[]
}>

export const DEFAULT_ASSET_NAME = 'Unnamed'

export const assetExternalIdState = atom({
  key: 'assetExternalIdState',
  default: DEFAULT_ASSET_EXTERNAL_ID,
})

export const validAssetTypesQuery = selectorFamily({
  key: 'validAssetTypesQuery',
  get:
    () =>
    async ({ get }) => {
      // We filter asset types based on asset data in the frontend because
      // there's no backend query to fetch all valid asset types with at least
      // one valid asset. This ensures we only display asset types that have
      // relevant data.
      const sessionGroup = get(sessionUserGroupState)
      const filter = { group: [sessionGroup] }
      const variables = {
        camAssetFirst: 1,
        assetTypeFilter: filter,
        canAssetFilter: filter,
      }
      const client = useApolloClient()
      const { data } = await client.query({
        query: GET_AVAILABLE_ASSET_TYPES,
        variables,
      })

      return _(getRelayEdgesData<AssetType>(data, ['assetTypes', 'all']))
        .reject(({ assets }) => _.isEmpty(getRelayEdgesData(assets, ['all'])))
        .map(({ assetTypeId, name }) => {
          return {
            assetTypeId,
            name: assetTypeId === ASSET_TYPES.site ? 'Asset' : name,
          }
        })
        .sortBy(({ assetTypeId }) => {
          return ASSET_TYPE_ID_ORDER.indexOf(assetTypeId as AssetTypeId)
        })
        .value()
    },
})

export const validAssetTypeIdsState = selector({
  key: 'validAssetTypeIdsState',
  get: ({ get }) => {
    const validAssetTypes = get(validAssetTypesQuery({}))
    return _.map(validAssetTypes, 'assetTypeId')
  },
})

const fetchAssetsByType = async ({
  get,
  assetType,
  queryParams,
}: {
  get: GetRecoilValue
  assetType: AssetTypeId
  queryParams?: AssetQueryParams
}) => {
  if (!_.includes([ASSET_TYPES.site, ASSET_TYPES.equipment], assetType)) {
    const validAssetTypeIds = get(validAssetTypeIdsState)
    if (!_.includes(validAssetTypeIds, assetType)) return []
  }

  const sessionGroup = get(sessionUserGroupState)
  const { data, error } = await getAssetsByType(assetType)({
    assetsOmitFields: ['geometryJson', 'group'],
    group: sessionGroup,
    ...queryParams,
  })

  if (error && _.isEmpty(data)) {
    throw new SuGraphqlError({
      error: `Failed to fetch asset ${assetType}s: ${error}`,
    })
  }

  return data
}

export const assetSitesListQuery = selectorFamily({
  key: 'assetSitesListQuery',
  get:
    () =>
    async ({ get }) => {
      const assetType = ASSET_TYPES.site
      const assetExternalId = get(assetExternalIdState)
      return fetchAssetsByType({
        get,
        assetType,
        queryParams: {
          assetsOmitFields: [],
          properties: _.compact([assetExternalId, 'name', 'isDAC', 'id']),
        },
      })
    },
})

export const assetSitesOptionsState = selector({
  key: 'assetSitesOptionsState',
  get: ({ get }) => {
    const list = get(assetSitesListQuery({}))
    const assetExternalId = get(assetExternalIdState)
    return _.map(
      list,
      ({ properties, assetReference, geometryJson, group }) => {
        const siteId = properties?.[assetExternalId]
        const label = _.trim(properties?.name) || DEFAULT_ASSET_NAME
        const observation = turfFeature(geometryJson)
        return {
          id: assetReference,
          properties,
          label,
          siteId,
          group,
          labelExtras: {
            description: siteId || label,
          },
          value: assetReference,
          ...(validateGeojson(observation) && {
            observation,
          }),
        }
      }
    )
  },
})

export const assetWellsListQuery = selectorFamily({
  key: 'assetWellsListQuery',
  get:
    () =>
    async ({ get }) => {
      const assetType = ASSET_TYPES.well
      const assetExternalId = get(assetExternalIdState)
      return fetchAssetsByType({
        get,
        assetType,
        queryParams: { properties: _.compact(['name', assetExternalId]) },
      })
    },
})

export const assetPipelinesListQuery = selectorFamily({
  key: 'assetWellsListQuery',
  get:
    () =>
    async ({ get }) => {
      const assetType = ASSET_TYPES.pipeline
      return fetchAssetsByType({
        get,
        assetType,
        queryParams: { properties: ['name'] },
      })
    },
})

export const assetWellsOptionsState = selector({
  key: 'assetWellsOptionsState',
  get: ({ get }) => {
    const assetExternalId = get(assetExternalIdState)
    const list = get(assetWellsListQuery({}))
    return _.map(list, ({ assetReference, properties }) => {
      return {
        value: assetReference,
        label:
          _.trim(properties?.name) ||
          properties?.[assetExternalId] ||
          DEFAULT_ASSET_NAME,
        labelExtras: {
          description: properties?.[assetExternalId],
        },
      }
    })
  },
})

export const assetPipelinesOptionsState = selector({
  key: 'assetPipelinesOptionsState',
  get: ({ get }) => {
    const list = get(assetPipelinesListQuery({}))
    return _.map(list, ({ assetReference, properties }) => {
      const { name } = properties || {}
      return {
        value: assetReference,
        label: _.trim(name) || DEFAULT_ASSET_NAME,
      }
    })
  },
})
